@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700,800);
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,700);
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  color: #202020;
  background: #141414; }

button:hover,
img:hover {
  cursor: pointer; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

a {
  transition: all 0.5s ease-in-out;
  cursor: pointer; }
  a:hover {
    color: #911a20; }

[id="name"]:focus-visible,
[id="name"]:focus,
input#name:focus-visible {
  outline: none !important; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

.Footer {
  font-family: "Roboto", sans-serif;
  background: #202020;
  color: white;
  display: -webkit-flex;
  display: flex;
  padding-left: 7rem;
  margin-top: 4rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 100%;
  -webkit-order: 1;
          order: 1; }
  @media screen and (min-width: 680px) and (max-width: 800px) {
    .Footer {
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-left: 7rem;
      padding-bottom: 6rem; } }
  @media screen and (min-width: 0px) and (max-width: 680px) {
    .Footer {
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-left: 0rem;
      display: -webkit-flex;
      display: flex;
      -webkit-align-content: center;
              align-content: center;
      -webkit-align-items: center;
              align-items: center;
      padding-bottom: 8rem; } }
  .Footer .footer--information .footer--information__title {
    padding-bottom: 2.2rem; }
    .Footer .footer--information .footer--information__title span {
      padding-right: 20px;
      font-size: 1rem; }
    @media screen and (min-width: 0px) and (max-width: 425px) {
      .Footer .footer--information .footer--information__title {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-flex-direction: column;
                flex-direction: column;
        line-height: 2.5rem; } }
  .Footer .footer--information .footer--information__placeholder {
    font-size: .78rem;
    display: -webkit-flex;
    display: flex;
    line-height: 1.5rem;
    padding-bottom: 1.8rem; }
    @media screen and (min-width: 0px) and (max-width: 680px) {
      .Footer .footer--information .footer--information__placeholder {
        width: 300px; } }
    .Footer .footer--information .footer--information__placeholder .footer--information__left {
      padding-right: 5rem; }
      @media screen and (min-width: 450px) and (max-width: 800px) {
        .Footer .footer--information .footer--information__placeholder .footer--information__left {
          padding-right: 13rem; } }
      @media screen and (min-width: 0px) and (max-width: 449px) {
        .Footer .footer--information .footer--information__placeholder .footer--information__left {
          padding-right: 6rem; } }
  .Footer .footer--information .footer--information__allrights {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: .78rem;
    margin-bottom: 3rem; }
  @media screen and (min-width: 0px) and (max-width: 449px) {
    .Footer .footer--information {
      line-height: 1.1; } }
  @media screen and (min-width: 450px) and (max-width: 800px) {
    .Footer .footer--social {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      height: 49px;
      width: 399px; } }
  @media screen and (min-width: 0px) and (max-width: 449px) {
    .Footer .footer--social {
      width: 351px; } }
  .Footer .footer--social .footer--social__logo {
    height: 70px;
    margin-bottom: 3rem;
    fill: #eb4e7a;
    max-width: 200px; }
    @media screen and (min-width: 0px) and (max-width: 800px) {
      .Footer .footer--social .footer--social__logo {
        width: 150px;
        margin-right: 108px; } }
  .Footer .footer--social .footer--social__media {
    width: 200px;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    fill: #1C262B;
    display: -webkit-flex;
    display: flex; }
    @media screen and (min-width: 0px) and (max-width: 1000px) {
      .Footer .footer--social .footer--social__media {
        -webkit-align-items: flex-end;
                align-items: flex-end; } }
    .Footer .footer--social .footer--social__media .footer--social__circle {
      background: #ADADAD;
      border-radius: 62%;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: 3rem;
      height: 3rem; }
      @media screen and (min-width: 0px) and (max-width: 800px) {
        .Footer .footer--social .footer--social__media .footer--social__circle {
          width: 2.5rem;
          height: 2.5rem; } }
      .Footer .footer--social .footer--social__media .footer--social__circle svg {
        background: #ADADAD;
        border-radius: 50%;
        width: 2rem; }
        @media screen and (min-width: 0px) and (max-width: 800px) {
          .Footer .footer--social .footer--social__media .footer--social__circle svg {
            width: 1.5rem;
            height: 1.5rem; } }
        .Footer .footer--social .footer--social__media .footer--social__circle svg:hover {
          fill: white;
          transition: .3s ease-in-out;
          cursor: pointer; }

/*********************FOOTER MEDIA QUERIES*******************************/
@media screen and (min-width: 1200px) {
  .Footer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; } }

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .footer--information,
  .footer--information__title,
  .footer--information__placeholder {
    width: 600px;
    justify-items: space-between; } }

@media screen and (min-width: 450px) and (max-width: 680px) {
  .footer--information,
  .footer--information__title,
  .footer--information__placeholder {
    width: 400px; } }

@media screen and (min-width: 0px) and (max-width: 449px) {
  .footer--information,
  .footer--information__title,
  .footer--information__placeholder {
    width: 350px; } }

@media screen and (min-width: 0px) and (max-width: 449px) {
  .footer--information__placeholder {
    width: 300px; } }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

.slider-divider {
  margin-left: 60px;
  margin-top: 5rem;
  width: 95%; }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    .slider-divider {
      margin-left: 0px;
      width: 100%; } }
  .slider-divider .movie--category {
    padding-bottom: 45px;
    padding-left: 7px;
    display: -webkit-flex;
    display: flex; }
  .slider-divider .movie--category__title {
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.9); }
  .slider-divider .movie--category__explore {
    font-family: "Roboto Condensed", sans-serif;
    font-size: .7rem;
    color: #2196f3;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    padding-left: 10px;
    padding-bottom: 1px; }
    .slider-divider .movie--category__explore:hover {
      opacity: .6;
      cursor: pointer;
      transition: .3s ease-in-out; }

.movie-details--home {
  position: absolute;
  z-index: 40;
  z-index: 40;
  top: 21px;
  background: rgba(0, 0, 0, 0.5);
  padding-right: 9px;
  border-radius: 1px;
  padding-top: 1px;
  padding-bottom: 1px; }
  @media only screen and (min-width: 1200px) {
    .movie-details--home {
      padding-right: 15px; } }
  .movie-details--home .movie-details--star__ratings {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-size: 1.2rem;
    -webkit-align-items: center;
            align-items: center; }
  .movie-details--home .movie-details--home__rating-num {
    padding-left: 5px;
    color: white; }
    @media only screen and (min-width: 1200px) {
      .movie-details--home .movie-details--home__rating-num {
        font-size: 1.3rem; } }
    @media only screen and (max-width: 900px) {
      .movie-details--home .movie-details--home__rating-num {
        font-size: .9rem; } }
  .movie-details--home .stars-rating-header__item {
    height: 20px;
    fill: #FFD700; }
    @media only screen and (min-width: 1200px) {
      .movie-details--home .stars-rating-header__item {
        height: 3rem; } }
    @media only screen and (min-width: 900px) {
      .movie-details--home .stars-rating-header__item {
        height: 20px; } }
    @media only screen and (min-width: 600px) {
      .movie-details--home .stars-rating-header__item .stars-rating-header__item {
        height: 10px; } }

.image {
  /* Safari and Chrome */
  /* Firefox */
  /* IE 9 */
  /* Opera */
  transition: all 2s ease;
  overflow: hidden; }
  .image img {
    opacity: .7; }
    .image img:hover {
      transition: all .7s ease;
      -webkit-transform: scale(1.25);
      /* Safari and Chrome */
      /* Firefox */
      /* IE 9 */
      /* Opera */
      transform: scale(1.05);
      opacity: 1; }

/*********************CAROUSEL COMPONENT WHICH BELONGS TO THIRD 
***********************PARTY LIBRARY
******************************/
.slick-prev {
  left: -1px;
  z-index: 30; }

.slick-next {
  right: 6px; }
  .slick-next:hover {
    background: rgba(0, 0, 0, 0.7);
    transition: all .3s ease-in-out; }

.slick-next, .slick-prev {
  z-index: 1;
  width: 40px;
  padding: 0;
  margin: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  outline: 0;
  height: 100%; }

.alternative--arrow:hover {
  background: rgba(0, 0, 0, 0.7);
  opacity: 1; }

.left--arrow__home {
  position: absolute;
  right: 25%;
  top: 45%; }
  .left--arrow__home:hover {
    -webkit-transform: translate3d(0, 1, 0);
            transform: translate3d(0, 1, 0); }

.right--arrow__home {
  position: absolute;
  right: 25%;
  top: 45%; }

button {
  display: none; }

.slick-prev:before {
  display: none; }

.slick-next:before {
  display: none; }

.x {
  position: absolute;
  top: 5%;
  right: 5%;
  line-height: 1;
  vertical-align: middle;
  font-size: 2em;
  outline: 1px solid #5E9ED6;
  outline-offset: 12px; }
  .x:hover {
    cursor: pointer; }

.x:hover {
  cursor: pointer; }

.outline {
  background: lightblue;
  padding-left: 3px;
  padding-right: 5px;
  border: solid blue thin; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

.home-swiper-container {
  overflow: hidden; }
  .home-swiper-container .swiper-pagination-progressbar-fill {
    background-color: #eb4e7a; }
  .home-swiper-container .swiper-wrapper {
    width: 100%;
    height: 100%; }
    .home-swiper-container .swiper-wrapper .swiper-slide {
      -webkit-align-items: center;
              align-items: center;
      background-size: cover, cover !important;
      display: -webkit-flex;
      display: flex;
      font-size: 18px;
      -webkit-justify-content: center;
              justify-content: center;
      height: 50vh;
      width: 100%;
      position: relative;
      text-align: center; }
      @media only screen and (min-width: 425px) {
        .home-swiper-container .swiper-wrapper .swiper-slide {
          height: 39vh; } }
      .home-swiper-container .swiper-wrapper .swiper-slide__image {
        height: auto;
        width: 100%; }
      .home-swiper-container .swiper-wrapper .swiper-slide__title {
        color: #fff;
        margin: 5px 0; }
      .home-swiper-container .swiper-wrapper .swiper-slide__item-duration, .home-swiper-container .swiper-wrapper .swiper-slide__category {
        color: rgba(255, 255, 255, 0.9);
        margin: 0; }
      .home-swiper-container .swiper-wrapper .swiper-slide a:active, .home-swiper-container .swiper-wrapper .swiper-slide a:hover, .home-swiper-container .swiper-wrapper .swiper-slide a:visited {
        color: #000;
        color: initial;
        text-decoration: none; }
  .home-swiper-container .home-swiper-pagination {
    z-index: 1; }

.home-swiper-pagination {
  z-index: 1; }

.header-info {
  width: 600px;
  height: 400px;
  position: absolute;
  color: white; }
  @media screen and (min-width: 425px) and (max-width: 749px) {
    .header-info {
      height: 14rem;
      margin-left: 6rem; } }
  @media screen and (max-width: 425px) {
    .header-info {
      display: none; } }
  .header-info .header-title {
    font-size: 3.5rem;
    line-height: 4rem;
    text-align: start; }
  .header-info .star-rating-and__info {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 17px; }
  .header-info .star {
    color: #2196f3;
    border: none;
    outline: none;
    background: transparent; }
  .header-info .synopsis {
    text-align: start;
    line-height: 1.6;
    font-family: "Roboto", sans-serif;
    color: #ffffff; }
  .header-info .watch--trailer {
    background-color: #202124;
    height: 55px;
    width: 173px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 50px;
    color: #fff;
    font-family: "Roboto Condensed", sans-serif;
    font-size: .9rem;
    letter-spacing: 1.2px;
    -webkit-justify-content: center;
            justify-content: center; }
    .header-info .watch--trailer:hover {
      background: #911a20;
      cursor: pointer;
      transition: 0.3s ease-in-out; }
    .header-info .watch--trailer .watch--trailer__icon {
      padding-right: 10px; }
      .header-info .watch--trailer .watch--trailer__icon svg {
        display: -webkit-flex;
        display: flex; }
  .header-info .info {
    font-family: "Roboto", sans-serif;
    padding-left: 20px;
    color: #999999; }
    @media screen and (min-width: 0px) and (max-width: 425px) {
      .header-info .info {
        display: none; } }

/*********************MEDIA QUERIES*******************************/
@media screen and (min-width: 0px) and (max-width: 750px) {
  .home-swiper-container {
    height: 300px; }
  .opacity-wrapper {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #000 0, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1));
    opacity: .8; }
    .opacity-wrapper .watch--trailer {
      display: none; }
  .synopsis {
    display: none; } }

.opacity-wrapper {
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  opacity: .8; }

@media screen and (min-width: 750px) and (max-width: 900px) {
  .opacity-wrapper {
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    opacity: .9; }
    .opacity-wrapper .header-info {
      top: 13rem; }
    .opacity-wrapper .synopsis {
      display: none; }
    .opacity-wrapper .watch--trailer {
      display: none; } }

@media only screen and (min-width: 900px) {
  .home-swiper-container .header-info {
    left: -10rem; }
  .home-swiper-container .swiper-wrapper {
    display: -webkit-flex;
    display: flex; }
    .home-swiper-container .swiper-wrapper .side-container {
      width: 30%;
      background: #000; }
    .home-swiper-container .swiper-wrapper .swiper-slide {
      height: 60vh;
      width: 70%; } }

@media only screen and (min-width: 1250px) {
  .home-swiper-container .swiper-wrapper {
    display: -webkit-flex;
    display: flex; }
    .home-swiper-container .swiper-wrapper .side-container {
      width: 35%;
      background: #000; }
    .home-swiper-container .swiper-wrapper .header-info {
      left: -18rem; }
    .home-swiper-container .swiper-wrapper .swiper-slide {
      height: 60vh;
      width: 65%; } }

.loader-container {
  background: linear-gradient(135deg, #eb4e7a 0%, #1c262b 71%);
  height: 100%;
  opacity: 1;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; }

.loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  -webkit-perspective: 800px;
          perspective: 800px;
  z-index: 1000; }

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1000; }

.inner.one {
  left: 0%;
  top: 0%;
  -webkit-animation: rotate-one 1s linear infinite;
          animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #EFEFFA;
  z-index: 1000; }

.inner.two {
  right: 0%;
  top: 0%;
  -webkit-animation: rotate-two 1s linear infinite;
          animation: rotate-two 1s linear infinite;
  border-right: 3px solid #EFEFFA;
  z-index: 1000; }

.inner.three {
  right: 0%;
  bottom: 0%;
  -webkit-animation: rotate-three 1s linear infinite;
          animation: rotate-three 1s linear infinite;
  border-top: 3px solid #EFEFFA;
  z-index: 1000; }

@-webkit-keyframes rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); } }

@keyframes rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); } }

@-webkit-keyframes rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg); } }

@keyframes rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg); } }

@-webkit-keyframes rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg); } }

@keyframes rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg); } }

@media screen and (min-width: 0px) and (max-width: 320px) {
  .Home {
    width: 112vw; } }

.MovieCard {
  padding-bottom: 20px; }
  .MovieCard .MovieCard__container {
    padding: 0;
    margin: 0;
    width: 200px;
    height: 292.5px; }
  .MovieCard img .searched-image {
    width: 200px;
    height: 292.5px; }
    .MovieCard img .searched-image:hover {
      -webkit-transform: scale(1);
              transform: scale(1); }
  .MovieCard .MovieCard--wrapper {
    padding-left: 5px;
    width: 200px;
    height: 292.5px; }
  .MovieCard .gray {
    padding-left: 6px; }

.MovieCard--wrapper {
  width: 200px;
  height: 292.5px; }

.card__img span {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 1.3rem; }

.card__img {
  height: 0;
  overflow: hidden;
  background-color: #202124;
  width: 200px;
  height: 292.5px;
  padding-top: 61%; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

.MovieList {
  margin-top: 9.3rem; }
  .MovieList .searchField-title {
    color: #aaaaaa;
    padding-left: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.3rem;
    text-transform: uppercase; }
  .MovieList .MovieList-Wrapper {
    padding: 2.5rem 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    background: #141414; }

/*******************MEDIA QUERIES*******************************/
@media (min-width: 1200px) {
  .MovieList-Wrapper {
    padding: 5rem; } }

@media (min-width: 640px) {
  .MovieList-Wrapper {
    padding: 3rem 0.5rem;
    padding-left: 1.7rem; } }

.footer {
  margin-top: 60px;
  margin-left: 4rem;
  margin-bottom: 50px; }
  @media screen and (min-width: 769px) {
    .footer {
      margin-bottom: 50px; } }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    .footer {
      margin-bottom: 5rem; } }
  .footer .footer--info__socialmedia,
  .footer .footer--info {
    color: #80868b;
    line-height: 2.5rem; }
  .footer .footer--info__socialmedia {
    padding-top: 20px;
    font-size: 1.4em; }
    .footer .footer--info__socialmedia .envelope {
      background: none;
      border: none;
      outline: none; }
      .footer .footer--info__socialmedia .envelope:hover {
        color: white;
        transition: 0.3s ease-in-out;
        cursor: pointer; }
    .footer .footer--info__socialmedia .github:hover {
      color: white;
      transition: 0.3s ease-in-out;
      cursor: pointer; }
    .footer .footer--info__socialmedia .linkedin:hover {
      color: white;
      transition: 0.3s ease-in-out;
      cursor: pointer; }
    .footer .footer--info__socialmedia .twitter:hover {
      color: white;
      transition: 0.3s ease-in-out;
      cursor: pointer; }

#previous {
  margin-right: 20px; }

#buttons-search {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0 auto;
  padding: 2.5rem 14.5rem;
  -webkit-justify-content: center;
          justify-content: center; }
  @media screen and (max-width: 425px) {
    #buttons-search {
      padding: 1.5rem 0em; } }

#buttons-search button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 18px;
  width: 8rem;
  font-family: Alegreya Sans,sans-serif;
  font-size: 1rem;
  text-transform: uppercase; }

.next-search {
  padding: 20px;
  color: #fff;
  background-color: #eb4e7a;
  text-transform: uppercase;
  cursor: pointer; }

.movie_card {
  background: black;
  font-family: 'Montserrat', helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  display: block;
  width: 800px;
  height: 350px;
  margin: 100px auto;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.4s; }
  .movie_card .info_section {
    position: relative;
    width: 100%;
    height: 100%;
    background-blend-mode: multiply;
    z-index: 2;
    border-radius: 10px; }
    .movie_card .info_section .movie_header {
      position: relative;
      padding: 25px;
      height: 40%; }
      .movie_card .info_section .movie_header h1 {
        color: #fff;
        font-weight: 400; }
      .movie_card .info_section .movie_header h4 {
        color: #9ac7fa;
        font-weight: 400; }
      .movie_card .info_section .movie_header .minutes {
        display: inline-block;
        margin-top: 10px;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.13); }
      .movie_card .info_section .movie_header .type {
        display: inline-block;
        color: #cee4fd;
        margin-left: 10px; }
      .movie_card .info_section .movie_header .locandina {
        position: relative;
        float: left;
        margin-right: 20px;
        height: 120px;
        box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5); }
    .movie_card .info_section .movie_desc {
      padding: 25px;
      height: 50%; }
      .movie_card .info_section .movie_desc .text {
        color: #cfd6e1; }
    .movie_card .info_section .movie_social {
      height: 10%;
      padding-left: 15px;
      padding-bottom: 20px; }
      .movie_card .info_section .movie_social ul {
        list-style: none;
        padding: 0; }
        .movie_card .info_section .movie_social ul li {
          display: inline-block;
          color: rgba(255, 255, 255, 0.4);
          transition: color 0.3s;
          transition-delay: 0.15s;
          margin: 0 10px; }
          .movie_card .info_section .movie_social ul li:hover {
            transition: color 0.3s;
            color: rgba(255, 255, 255, 0.8); }
          .movie_card .info_section .movie_social ul li i {
            font-size: 19px;
            cursor: pointer; }
  .movie_card .blur_back {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    right: 0;
    background-size: cover;
    border-radius: 11px; }

@media screen and (min-width: 768px) {
  .movie_header {
    width: 60%; }
  .movie_desc {
    width: 50%; }
  .info_section {
    background: linear-gradient(to right, #0d0d0c 50%, transparent 100%); }
  .blur_back {
    width: 80%;
    background-position: -100% 10% !important; } }

@media screen and (max-width: 768px) {
  .movie_card {
    width: 95%;
    margin: 70px auto;
    min-height: 350px;
    height: auto; }
  .blur_back {
    width: 100%;
    background-position: 50% 50% !important; }
  .movie_header {
    width: 100%;
    margin-top: 85px; }
  .movie_desc {
    width: 100%; }
  .info_section {
    background: linear-gradient(to top, #141413 50%, transparent 100%);
    display: inline-grid; } }

#bright {
  box-shadow: 0px 0px 150px -45px rgba(255, 51, 0, 0.5); }
  #bright:hover {
    box-shadow: 0px 0px 120px -55px rgba(255, 51, 0, 0.5); }

.PeopleCarrousel .people__carousel--title {
  font-size: 2rem;
  margin-bottom: 2.5rem; }

.people__info--image {
  opacity: 1 !important; }

.slick-dots {
  visibility: hidden; }

.left--arrow__people,
.right--arrow__people {
  position: absolute;
  top: 34%; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

*, *:before, *:after {
  box-sizing: border-box; }

.MovieDetails {
  background: white; }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    .MovieDetails {
      margin-left: 0px; } }
  @media screen and (min-width: 769px) {
    .MovieDetails {
      margin-left: 4rem; } }

a {
  text-decoration: none;
  color: #629af3; }

.movie-card {
  font: 14px/22px "Lato", Arial, sans-serif;
  color: #A9A8A3;
  padding: 0; }

.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.hero {
  height: 342px;
  margin: 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%; }
  @media screen and (min-width: 0px) and (max-width: 800px) {
    .hero {
      height: 488px; } }

.hero:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)) center center no-repeat;
  -webkit-backface-visibility: hidden; }

.cover {
  position: absolute;
  border-radius: 11px;
  top: 160px;
  left: 40px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  z-index: 2;
  width: 187px; }
  @media screen and (min-width: 0px) and (max-width: 425px) {
    .cover {
      top: 195px;
      left: 19px; } }

.title__and--ratings {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

/**************************** MOVIE DETAILS ************************************/
.details {
  margin-top: 11rem;
  margin-left: 16rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (min-width: 0px) and (max-width: 425px) {
    .details {
      margin-top: 1rem;
      margin-left: 1rem; } }
  .details .title1 {
    color: white;
    font-size: 44px;
    margin-bottom: 13px;
    position: relative;
    line-height: 36px; }
    .details .title1 .rated__pg {
      top: 3px;
      margin-left: 12px;
      background: #FFD700;
      border-radius: 6px;
      color: #544C21;
      font-size: 14px;
      padding: 3px 4px; }
    .details .title1 .rated__R {
      top: 3px;
      margin-left: 12px;
      background: #cc0000;
      border-radius: 5px;
      color: white;
      font-size: 14px;
      padding: 2px 14px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
      font-weight: 900; }
  .details .title2 {
    color: #C7C1BA;
    font-size: 23px;
    font-weight: 300;
    margin-bottom: 15px; }
  .details .likes {
    margin-left: 28px; }
  .details .likes:before {
    position: relative;
    top: 2px;
    padding-right: 7px; }

.description {
  bottom: 0px;
  font-size: 16px;
  line-height: 26px;
  color: #B1B0AC;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 20px; }

.column1 {
  padding-left: 50px;
  top: 7rem;
  position: relative;
  width: 231px;
  text-align: center; }
  @media screen and (min-width: 0px) and (max-width: 800px) {
    .column1 {
      display: none; } }

.tag {
  background: #ff0000;
  color: white;
  border-radius: 10px;
  padding: 3px 8px;
  font-size: 14px;
  margin-right: 4px;
  line-height: 35px;
  cursor: pointer;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0), 0 5px 8px -1px rgba(0, 0, 0, 0.22); }

.tag:hover {
  background: white;
  color: #ff0000;
  -webkit-transform: scale(1.9);
          transform: scale(1.9);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -webkit-transform: translate3d(0, -1px, 0);
          transform: translate3d(0, -1px, 0); }

.column2 {
  padding-left: 41px;
  margin-left: 17rem;
  padding-right: 2rem; }
  @media screen and (min-width: 426px) and (max-width: 800px) {
    .column2 {
      padding-left: 0px;
      padding-top: 4rem;
      margin-left: 2.5rem; } }
  @media screen and (min-width: 0px) and (max-width: 425px) {
    .column2 {
      padding-left: 0.2rem;
      font-size: .8rem;
      width: 100%;
      margin-left: 0.1rem; } }

.avatars {
  margin-top: 23px; }
  .avatars img {
    cursor: pointer; }
  .avatars img:hover {
    opacity: 0.6; }
  .avatars a:hover {
    text-decoration: none; }

fieldset, label {
  margin: 0;
  padding: 0; }

/**************************** RATINGS AND LIKES  ************************************/
.ratings__and--likes {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.rating {
  border: none; }

.rating > input {
  display: none; }

.rating > label:before {
  margin: 5px;
  margin-top: 0;
  font-size: 1em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005"; }

.rating > .half:before {
  content: "\f089";
  position: absolute; }

.rating > label {
  color: #ddd; }

/****************************SCSS THAT HIGHLIGHTS ON HOVER  ************************************/
.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #FFD700; }

/* hover previous stars in list */
.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  color: #FFED85; }

a[data-tooltip] {
  position: relative; }

a[data-tooltip]::before,
a[data-tooltip]::after {
  position: absolute;
  display: none;
  opacity: 0.85; }

a[data-tooltip]::before {
  content: attr(data-tooltip);
  background: #000;
  color: #fff;
  font-size: 13px;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  text-decoration: none; }

a[data-tooltip]::after {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  content: ''; }

a[data-tooltip]:hover::before,
a[data-tooltip]:hover::after {
  display: block; }

/* top tooltip */
a[data-tooltip][data-placement="top"]::before {
  bottom: 100%;
  left: 0;
  margin-bottom: 40px; }

a[data-tooltip][data-placement="top"]::after {
  border-top-color: #000;
  border-bottom: none;
  bottom: 50px;
  left: 20px;
  margin-bottom: 4px; }

/**************************** STAR RATING CONTAINER  ************************************/
.star-rating-container {
  display: grid;
  grid-template-columns: repeat(5, 15px);
  grid-template-rows: 15px;
  position: relative;
  width: 75px; }
  .star-rating-container__item {
    cursor: pointer;
    fill: white;
    height: 15px;
    width: 15px; }
    .star-rating-container__item--active {
      fill: #FFD700; }
  .star-rating-container-warning {
    background-color: rgba(255, 0, 0, 0.7);
    color: red;
    font-weight: bold;
    left: -2rem;
    padding: .5rem;
    position: absolute;
    top: 1.5rem;
    width: 200px; }
    .star-rating-container-warning--hide {
      display: none; }

.play_AfXd1 {
  position: absolute;
  z-index: 1;
  padding: 0;
  margin: 0;
  background: none;
  z-index: 100;
  border: none;
  top: 250px;
  left: 102px;
  height: 40px;
  width: 55px; }
  @media screen and (min-width: 0px) and (max-width: 425px) {
    .play_AfXd1 {
      top: 20rem;
      left: 86px; } }

.secondary__image {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 8px; }

.secondary__image--wrapper :hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  transition: all .3s ease-in-out;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  cursor: pointer; }

.video :hover {
  cursor: pointer; }

.break {
  padding-left: 15px; }

/**************************** GENERAL INFORMATION ************************************/
.production__info--section {
  margin-left: 19.55rem; }
  @media screen and (min-width: 426px) and (max-width: 1200px) {
    .production__info--section {
      margin-left: 2.6rem;
      margin-bottom: 4rem; } }
  @media screen and (min-width: 0px) and (max-width: 426px) {
    .production__info--section {
      margin-left: 0.4rem;
      margin-bottom: 4rem; } }
  .production__info--section .production__info {
    line-height: 2rem; }
    .production__info--section .production__info .production__info--wrapper {
      margin-bottom: 3rem;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      font-size: 1rem; }
      @media screen and (min-width: 0px) and (max-width: 650px) {
        .production__info--section .production__info .production__info--wrapper {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          margin-left: 0; } }
      .production__info--section .production__info .production__info--wrapper .production__info--left {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row; }
        .production__info--section .production__info .production__info--wrapper .production__info--left .info__left--placeholder {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          color: #000; }
        .production__info--section .production__info .production__info--wrapper .production__info--left .info__left--data {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          margin-left: 1.5rem; }
      .production__info--section .production__info .production__info--wrapper .production__info--right {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        padding-left: 5rem; }
        @media screen and (min-width: 0px) and (max-width: 650px) {
          .production__info--section .production__info .production__info--wrapper .production__info--right {
            padding-left: 0; } }
        .production__info--section .production__info .production__info--wrapper .production__info--right .info__right--placeholder {
          color: #000;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
        .production__info--section .production__info .production__info--wrapper .production__info--right .info__right--data {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          margin-left: 1.5rem; }
    .production__info--section .production__info .production__info--media #icons {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row; }

#icon,
.tmdb {
  color: #141414;
  font-size: 1.3rem;
  margin-right: 2rem; }
  #icon:hover,
  .tmdb:hover {
    color: #911a20;
    cursor: pointer; }

.tmdb {
  fill: #141414; }
  .tmdb:hover {
    fill: #911a20;
    cursor: pointer; }

@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

.SignIn {
  background: #f6f5f7;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  height: 100%; }
  @media screen and (min-width: 1800px) {
    .SignIn {
      height: 100vh; } }
  .SignIn .main-title {
    padding-bottom: 1rem;
    margin-top: 3rem;
    font-family: "Roboto", sans-serif;
    font-size: 2rem;
    color: #333; }
    @media screen and (min-width: 1800px) {
      .SignIn .main-title {
        margin-left: -52.5rem; } }
    @media screen and (min-width: 950px) {
      .SignIn .main-title {
        margin-left: -33.5rem; } }
    @media screen and (min-width: 700px) and (max-width: 949px) {
      .SignIn .main-title {
        margin-left: 0; } }
    @media screen and (min-width: 0px) and (max-width: 699px) {
      .SignIn .main-title {
        margin-left: 0; } }
  .SignIn .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
    margin-bottom: 2rem;
    margin-top: 3rem; }
    @media screen and (min-width: 1800px) {
      .SignIn .container {
        width: 54vw;
        margin-bottom: 10rem;
        margin-top: 10rem; } }
  .SignIn .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1; }
  .SignIn .form-title {
    font-weight: bold;
    margin: 0; }
  .SignIn .social-container {
    margin: 20px 0; }
    .SignIn .social-container a {
      border: 1px solid #DDDDDD;
      border-radius: 50%;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin: 0 5px;
      height: 40px;
      width: 40px; }
  .SignIn .btn {
    border-radius: 20px;
    border: 1px solid #FF4B2B;
    background-color: #FF4B2B;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
    display: block;
    cursor: pointer;
    white-space: nowrap; }
    @media screen and (max-width: 425px) {
      .SignIn .btn {
        padding: 13px 32%;
        margin-top: 7px; } }
    .SignIn .btn:active {
      -webkit-transform: scale(0.95);
              transform: scale(0.95); }
    .SignIn .btn:focus {
      outline: none; }
    .SignIn .btn.ghost {
      background-color: transparent;
      border-color: #FFFFFF; }
    .SignIn .btn .social {
      cursor: pointer; }
  .SignIn .sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2; }
  .SignIn span {
    font-size: 12px; }
  .SignIn input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%; }
  .SignIn .sign-paragraph {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px; }
  .SignIn form {
    background-color: #FFFFFF;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center; }
    @media screen and (max-width: 425px) {
      .SignIn form {
        padding: 0 20px; } }
  .SignIn .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out; }
  .SignIn a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0; }
  .SignIn .container.right-panel-active .sign-in-container {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  .SignIn .container.right-panel-active .sign-up-container {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    -webkit-animation: show 0.6s;
            animation: show 0.6s; }
  .SignIn .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
    z-index: 100; }
  .SignIn .container.right-panel-active .overlay-container {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .SignIn .overlay {
    background: #FF416C;
    background: linear-gradient(to right, #FF4B2B, #FF416C);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  .SignIn .container.right-panel-active .overlay {
    -webkit-transform: translateX(50%);
            transform: translateX(50%); }
  .SignIn .overlay-panel {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
    @media screen and (max-width: 425px) {
      .SignIn .overlay-panel {
        padding: 0 20px; } }
  .SignIn .overlay-left {
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%); }
  .SignIn .container.right-panel-active .overlay-left {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .SignIn .overlay-right {
    right: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .SignIn .container.right-panel-active .overlay-right {
    -webkit-transform: translateX(20%);
            transform: translateX(20%); }
  @media screen and (min-width: 950px) {
    .SignIn #sign-in-footer {
      margin-left: -23rem; } }
  @media screen and (min-width: 700px) and (max-width: 949px) {
    .SignIn #sign-in-footer {
      margin-left: -19; } }
  @media screen and (min-width: 0px) and (max-width: 699px) {
    .SignIn #sign-in-footer {
      margin-left: 0;
      margin-bottom: 7rem; } }

#signIn {
  white-space: nowrap; }

@-webkit-keyframes show {
  0%, 49.99% {
    opacity: 0;
    z-index: 1; }
  50%, 100% {
    opacity: 1;
    z-index: 5; } }

@keyframes show {
  0%, 49.99% {
    opacity: 0;
    z-index: 1; }
  50%, 100% {
    opacity: 1;
    z-index: 5; } }

/*********************SIDE BAR*******************************/
.main-nav {
  z-index: 2000;
  background: #000; }
  @media screen and (min-width: 769px) {
    .main-nav {
      top: 0;
      right: auto;
      z-index: 2000;
      width: 4rem;
      position: fixed;
      background: #000;
      height: 100vh; } }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    .main-nav {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 5;
      height: 4rem;
      background-color: #000; } }
  @media screen and (min-width: 769px) {
    .main-nav .main-nav__icons {
      -webkit-align-items: center;
              align-items: center;
      border-right: 1px solid #202124;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding: 0;
      margin: 0;
      margin-top: 97%;
      list-style: none;
      position: -webkit-sticky;
      position: sticky; } }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    .main-nav .main-nav__icons {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      -webkit-flex-direction: row;
              flex-direction: row;
      padding-top: 1rem; } }
  .main-nav .main-nav__icons .home {
    color: #2196f3; }
  .main-nav .main-nav__icons:hover {
    cursor: pointer; }
  @media screen and (min-width: 769px) {
    .main-nav .main-nav__icons .main-nav__icon {
      -webkit-flex: 0 1 auto;
              flex: 0 1 auto;
      height: 10rem; } }

/*********************SEARCHBAR NAV*******************************/
.pa {
  font-size: 1rem;
  color: black;
  border: 0;
  outline: 0;
  position: fixed;
  left: 4.32rem;
  top: 3.7rem;
  transition: all 0.3s cubic-bezier(0, 0.52, 0, 1); }
  @media screen and (min-width: 769px) {
    .pa {
      left: 4.32rem; } }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    .pa {
      left: 2rem; } }

/*********************SEARCH BAR ANIMATIONS*******************************/
.hide {
  -webkit-transform: translate3d(-100vw, 0, 0);
          transform: translate3d(-100vw, 0, 0);
  transition: -webkit-transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1), -webkit-transform 0.3s cubic-bezier(0, 0.52, 0, 1); }

.show {
  -webkit-transform: translate3d(0vw, 0, 0);
          transform: translate3d(0vw, 0, 0);
  transition: -webkit-transform 0.4s cubic-bezier(0, 0.52, 0, 1);
  transition: transform 0.4s cubic-bezier(0, 0.52, 0, 1);
  transition: transform 0.4s cubic-bezier(0, 0.52, 0, 1), -webkit-transform 0.4s cubic-bezier(0, 0.52, 0, 1); }

.measure__ {
  width: 250px; }

#name {
  background-color: white; }

input {
  border-radius: 20px;
  color: black; }

/*********************SEARCH BAR COMPONENTS*******************************/
.main-nav-search-form {
  position: relative; }
  .main-nav-search-form__input {
    border: none;
    border-radius: 20px;
    padding: 10px;
    outline: none; }
  .main-nav-search-form__button {
    background: #EB4E7A;
    border: none;
    border-radius: 86px;
    cursor: pointer;
    height: 36px;
    left: 199px;
    outline: none;
    position: absolute;
    top: 0;
    width: 51px;
    display: -webkit-flex;
    display: flex; }
    .main-nav-search-form__button .svg {
      fill: white;
      -webkit-align-content: center;
              align-content: center;
      -webkit-align-items: center;
              align-items: center;
      padding-left: 11px; }

