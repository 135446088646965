@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Rozha+One&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap");
@font-face {
  font-family: road_rage;
  src: url("/fonts/Road_Rage.otf"); }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  color: #202020;
  background: #141414; }

button:hover,
img:hover {
  cursor: pointer; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

a {
  transition: all 0.5s ease-in-out;
  cursor: pointer; }
  a:hover {
    color: #911a20; }

[id="name"]:focus-visible,
[id="name"]:focus,
input#name:focus-visible {
  outline: none !important; }
