.x  {
    position: absolute;
    top: 5%;
    right: 5%;
    line-height: 1;
    vertical-align: middle;
    font-size: 2em;
    outline: 1px solid #5E9ED6;
    outline-offset: 12px;
    &:hover{
        cursor: pointer;
    }
}
.x:hover {
    cursor: pointer;
}

.outline{
    background: lightblue; 
    padding-left: 3px;
    padding-right: 5px;
    border: solid blue thin;
}