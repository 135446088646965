.footer {
  margin-top: 60px;
  margin-left: 4rem;
  margin-bottom: 50px;
  @media screen and (min-width: 769px) {
    margin-bottom: 50px;
  }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-bottom: 5rem;
  }
  .footer--info__socialmedia,
  .footer--info {
    color: #80868b;
    line-height: 2.5rem;
  }
  .footer--info__socialmedia {
    padding-top: 20px;
    font-size: 1.4em;
    .envelope {
      background: none;
      border: none;
      outline: none;
      &:hover {
        color: white;
        transition: 0.3s ease-in-out;
        cursor: pointer;
      }
    }
    .github {
      &:hover {
        color: white;
        transition: 0.3s ease-in-out;
        cursor: pointer;
      }
    }
    .linkedin {
      &:hover {
        color: white;
        transition: 0.3s ease-in-out;
        cursor: pointer;
      }
    }
    .twitter {
      &:hover {
        color: white;
        transition: 0.3s ease-in-out;
        cursor: pointer;
      }
    }
  }
}

#previous {
  margin-right: 20px;
}

#buttons-search {
  display: flex !important;
  flex-direction: row;
  margin: 0 auto;
  padding: 2.5rem 14.5rem;
  justify-content: center;
  @media screen and (max-width: 425px) {
    padding: 1.5rem 0em;
  }
}

#buttons-search button {
  display: flex;
  align-items: center;
  padding-left: 18px;
  width: 8rem;
  font-family: Alegreya Sans,sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}

.next-search {
  padding: 20px;
  color: #fff;
  background-color: #eb4e7a;
  text-transform: uppercase;
  cursor: pointer;
}
