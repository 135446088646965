.MovieCard{
    padding-bottom: 20px;
    .MovieCard__container{
        padding: 0;
        margin: 0;
        width: 200px;
        height: 292.5px;
    }
    img .searched-image{
        width: 200px;
        height: 292.5px;
        &:hover {
            transform: scale(1);
        }
    }
    .MovieCard--wrapper{
        padding-left: 5px;
        width: 200px;
        height: 292.5px;
    }
    .gray{
        padding-left: 6px;
    }
}

.MovieCard--wrapper {
    width: 200px;
    height: 292.5px;
}

.card__img span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
}

.card__img {
    height: 0;
    overflow: hidden;
    background-color: #202124;
    width: 200px;
    height: 292.5px;
    padding-top: 61%;
}
