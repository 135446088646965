
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
@import '../../styles/_variables.scss';

.SignIn {
	background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    @media screen  and (min-width: 1800px) {
        height: 100vh;
    }
    .main-title{
        padding-bottom: 1rem;
        margin-top: 3rem;
        font-family: "Roboto", sans-serif;
        font-size: 2rem;
        color: #333;
        @media screen  and (min-width: 1800px) {
            margin-left: -52.5rem;
        }
        @media screen  and (min-width: 950px) {
            margin-left: -33.5rem;
        }
        @media screen and (min-width: 700px) and (max-width: 949px) {
            margin-left: 0;
        }
        @media screen and (min-width: 0px) and (max-width: 699px) {
            margin-left: 0;
        }
    }
    .container {
        background-color: #fff;
        border-radius: 10px;
          box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
                0 10px 10px rgba(0,0,0,0.22);
        position: relative;
        overflow: hidden;
        width: 768px;
        max-width: 100%;
        min-height: 480px;
        margin-bottom: 2rem;
        margin-top: 3rem;
        @media screen  and (min-width: 1800px) {
            width: 54vw;
            margin-bottom: 10rem;
            margin-top: 10rem;
        }
    }
    .sign-up-container {
        left: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;
    }
    .form-title{
        font-weight: bold;
	    margin: 0;
    }
    .social-container {
        margin: 20px 0;
        a{
            border: 1px solid #DDDDDD;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: 0 5px;
            height: 40px;
            width: 40px;
        }
    }
    .btn {
        border-radius: 20px;
        border: 1px solid #FF4B2B;
        background-color: #FF4B2B;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: bold;
        padding: 12px 45px;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: transform 80ms ease-in;
        display: block;
        cursor: pointer;
        white-space: nowrap;
        @media screen  and (max-width: 425px) {
            padding: 13px 32%;
            margin-top: 7px;
        }
        &:active {
            transform: scale(0.95);
        }
        
        &:focus {
            outline: none;
        }
        
        &.ghost {
            background-color: transparent;
            border-color: #FFFFFF;
        }
        .social{
            cursor: pointer;
        }
    }
    
    .sign-in-container {
        left: 0;
        width: 50%;
        z-index: 2;
    }
    span {
        font-size: 12px;
    }
    input {
        background-color: #eee;
        border: none;
        padding: 12px 15px;
        margin: 8px 0;
        width: 100%;
    }    
    .sign-paragraph {
        font-size: 14px;
        font-weight: 100;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin: 20px 0 30px;
    }
    form {
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 50px;
        height: 100%;
        text-align: center;
        @media screen  and (max-width: 425px) {
            padding: 0 20px;
        }
    }
    .form-container {
        position: absolute;
        top: 0;
        height: 100%;
        transition: all 0.6s ease-in-out;
    }
    a {
        color: #333;
        font-size: 14px;
        text-decoration: none;
        margin: 15px 0;
    }
    .container.right-panel-active .sign-in-container {
        transform: translateX(100%);
    }
    
    
    .container.right-panel-active .sign-up-container {
        transform: translateX(100%);
        opacity: 1;
        z-index: 5;
        animation: show 0.6s;
    }    
    .overlay-container {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: transform 0.6s ease-in-out;
        z-index: 100;
    }
    
    .container.right-panel-active .overlay-container{
        transform: translateX(-100%);
    }
    
    .overlay {
        background: #FF416C;
        background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
        background: linear-gradient(to right, #FF4B2B, #FF416C);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        color: #FFFFFF;
        position: relative;
        left: -100%;
        height: 100%;
        width: 200%;
          transform: translateX(0);
        transition: transform 0.6s ease-in-out;
    }
    
    .container.right-panel-active .overlay {
          transform: translateX(50%);
    }
    
    .overlay-panel {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 40px;
        text-align: center;
        top: 0;
        height: 100%;
        width: 50%;
        transform: translateX(0);
        transition: transform 0.6s ease-in-out;
        @media screen  and (max-width: 425px) {
            padding: 0 20px;
        }
    }
    
    .overlay-left {
        transform: translateX(-20%);
    }
    
    .container.right-panel-active .overlay-left {
        transform: translateX(0);
    }
    
    .overlay-right {
        right: 0;
        transform: translateX(0);
    }
    
    .container.right-panel-active .overlay-right {
        transform: translateX(20%);
    }        
    #sign-in-footer{
        @media screen  and (min-width: 950px) {
            margin-left: -23rem;
        }
        @media screen and (min-width: 700px) and (max-width: 949px) {
            margin-left: -19;
        }
        @media screen and (min-width: 0px) and (max-width: 699px) {
            margin-left: 0;
            margin-bottom: 7rem;
        }
    }
}

#signIn {
    white-space: nowrap;
}



@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

