@import url(https://fonts.googleapis.com/css?family=Lato:400,300,700);
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@import '../../styles/_variables.scss';

*, *:before, *:after {
  box-sizing: border-box;
}

.MovieDetails{
  background: white;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-left: 0px;
  }
  @media screen  and (min-width: 769px) {
    margin-left: 4rem;
  }
}

a {
  text-decoration: none;
  color: rgb(98, 154, 243)
}

.movie-card {
  font: 14px/22px "Lato", Arial, sans-serif;
  color: #A9A8A3;
  padding: 0;
}

.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: white;;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.hero {
  height: 342px;  
  margin:0;
  position: relative;
  overflow: hidden;
  z-index:1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  @media screen and (min-width: 0px) and (max-width: 800px) {
    height: 488px;  
  }
}

.hero:before {
  content:'';
  width:100%; height:100%;
  position:absolute;
  overflow: hidden;
  top:0; left:0;
  z-index:-1;
  background: linear-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6)) center center no-repeat;
  -webkit-backface-visibility: hidden; 
}



.cover {
  position: absolute;
  border-radius: 11px;
  top: 160px;
  left: 40px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  z-index: 2;
  width: 187px;
  @media screen and (min-width: 0px) and (max-width: 425px) {
  top: 195px;
  left: 19px;
  }
}

.title__and--ratings {
  display: flex;
  flex-wrap: wrap;
}

/**************************** MOVIE DETAILS ************************************/


.details {
  margin-top: 11rem;
  margin-left: 16rem;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 0px) and (max-width: 425px) {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .title1 {
    color: white;
    font-size: 44px;
    margin-bottom: 13px;
    position: relative;
    line-height: 36px;
    .rated__pg {
      top: 3px;
      margin-left: 12px;
      background: #FFD700;
      border-radius: 6px;
      color: #544C21;
      font-size: 14px;
      padding: 3px 4px;
    }
    .rated__R{
      top: 3px;
      margin-left: 12px;
      background:#cc0000;
      border-radius: 5px;
      color: white;
      font-size: 14px;
      padding: 2px 14px;
      box-shadow: 0 0 3px rgba(0,0,0,0.6);
      font-weight: 900;
    }
  }

  .title2 {    
    color: #C7C1BA;
    font-size: 23px;    
    font-weight: 300;
    margin-bottom: 15px;
  }
  
  
  .likes {
    margin-left: 28px;
  }
  
  
  .likes:before {
    position: relative;
    top: 2px;
    padding-right: 7px;
  }

}

.description {
  bottom: 0px;
  font-size: 16px;
  line-height: 26px;
  color: #B1B0AC;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.column1 {
  padding-left: 50px;
  top: 7rem;
  position: relative;
  width: 231px;
  text-align: center;
  @media screen and (min-width: 0px) and (max-width: 800px) {
    display: none;
  }
}

.tag {
    background: #ff0000;
    color: white;
    border-radius: 10px;
    padding: 3px 8px;
    font-size: 14px;
    margin-right: 4px;
    line-height: 35px;
    cursor: pointer;
    box-shadow: 0 0px 1px rgba(0, 0, 0, 0), 0 5px 8px -1px rgba(0, 0, 0, 0.22);
}

.tag:hover {
  background: white;
  color: #ff0000;
  transform: scale(1.9); 
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transform: translate3d(0, -1px, 0)
}

.column2 {
  padding-left: 41px;
  margin-left: 17rem;
  padding-right: 2rem;
  @media screen and (min-width: 426px) and (max-width: 800px) {
    padding-left: 0px;
    padding-top: 4rem;
    margin-left: 2.5rem;
  }
  @media screen and (min-width: 0px) and (max-width: 425px) {
    padding-left: 0.2rem;
    font-size: .8rem;
    width: 100%;
    margin-left: 0.1rem;
  }
}

.avatars {
  margin-top: 23px;
  
  img {
    cursor: pointer;
  }
  
  img:hover {
    opacity: 0.6;
  }
  
  a:hover {
    text-decoration: none;
  }
}


fieldset, label { margin: 0; padding: 0; }

/**************************** RATINGS AND LIKES  ************************************/

.ratings__and--likes{
  display: flex;
  flex-direction: row;
}

.rating { 
  border: none;
  
}

.rating > input { display: none; } 
.rating > label:before { 
  margin: 5px;
  margin-top: 0;
  font-size: 1em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating > .half:before { 
  content: "\f089";
  position: absolute;
}

.rating > label { 
  color: #ddd; 
 
}


/****************************SCSS THAT HIGHLIGHTS ON HOVER  ************************************/


.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label { color: #FFD700;  } /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label { color: #FFED85;  } 


a[data-tooltip] {
  position: relative;
}
a[data-tooltip]::before,
a[data-tooltip]::after {
  position: absolute;
  display: none;
  opacity: 0.85;
}
a[data-tooltip]::before {
  content: attr(data-tooltip);
  background: #000;
  color: #fff;
  font-size: 13px;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  text-decoration: none;
}
a[data-tooltip]::after {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  content: '';
}

a[data-tooltip]:hover::before,
a[data-tooltip]:hover::after {
  display: block;
}


/* top tooltip */
a[data-tooltip][data-placement="top"]::before {
  bottom: 100%;
  left: 0;
  margin-bottom: 40px;
}
a[data-tooltip][data-placement="top"]::after {
  border-top-color: #000;
  border-bottom: none;
  bottom: 50px;
  left: 20px;
  margin-bottom: 4px;
}


/**************************** STAR RATING CONTAINER  ************************************/


.star-rating-container {
  display: grid;
  grid-template-columns: repeat(5, 15px);
  grid-template-rows: 15px;
  position: relative;
  width: 15px * 5;

  &__item {
    cursor: pointer;
    fill: white;
    height: 15px;
    width: 15px;

    &--active {
      fill: #FFD700;
    }
  }

  &-warning {
    background-color: rgba(255, 0 ,0, .7);
    color: red;
    font-weight: bold;
    left: -2rem;
    padding: .5rem;
    position: absolute;
    top: 1.5rem;
    width: 200px;

    &--hide {
      display: none;
    }
  }
}

.play_AfXd1 {
  position: absolute;
  z-index: 1;
  padding: 0;
  margin: 0;
  background: none;
  z-index: 100;
  border: none;
  top: 250px;
  left: 102px;
  height: 40px;
  width: 55px;
  @media screen and (min-width: 0px) and (max-width: 425px) {
    top: 20rem;
    left: 86px;
  }
}


.secondary__image{
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  border-radius: 8px;
}

.secondary__image--wrapper{
  :hover{
    transform: scale(1.03);
    transition: all .3s ease-in-out; 
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    cursor: pointer;
  } 
}

.video{
  :hover{
    cursor: pointer;
  } 
}

.break{
  padding-left: 15px;
}

/**************************** GENERAL INFORMATION ************************************/

.production__info--section{
  margin-left: 19.55rem;
  @media screen and (min-width: 426px) and (max-width: 1200px) {
    margin-left: 2.6rem;
    margin-bottom: 4rem;
  }
  @media screen and (min-width: 0px) and (max-width: 426px) {
    margin-left: 0.4rem;
    margin-bottom: 4rem;
  }
  .production__info{
    line-height: 2rem;
    .production__info--wrapper{
      margin-bottom: 3rem;
      display: flex;
      flex-direction: row;
      font-size: 1rem;
      @media screen and (min-width: 0px) and (max-width: 650px) {
        display: flex;
        flex-direction: column;
        margin-left: 0;
      }
      .production__info--left{
        display: flex;
        flex-direction: row;
        .info__left--placeholder{
          display: flex;
          flex-direction: column;
          color: #000;
        }
        .info__left--data{
          display: flex;
          flex-direction: column;
          margin-left: 1.5rem;
        }
      }

      .production__info--right{
        display: flex;
        flex-direction: row;
        padding-left: 5rem;
        @media screen and (min-width: 0px) and (max-width: 650px) {
          padding-left: 0;
        }
        .info__right--placeholder{
          color: #000;
          display: flex;
          flex-direction: column;
        }
        .info__right--data{
          display: flex;
          flex-direction: column;
          margin-left: 1.5rem;
        }
      }
    }
    .production__info--media{
      #icons{
        display: flex;
        flex-direction: row;
      }
    }
  }
}
#icon, 
.tmdb {
  color: #141414;
  font-size: 1.3rem;
  margin-right: 2rem;
  &:hover{
    color: $red;
    cursor: pointer;
  }
}

.tmdb{
  fill: #141414;
  &:hover{
    fill: $red;
    cursor: pointer;
  }
}


