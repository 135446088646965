@import '../../styles/_variables';

.home-swiper-container {
  overflow: hidden;
  

  .swiper-pagination-progressbar-fill {
    background-color: $secondary-color;
  }

  .swiper-wrapper {
    width: 100%;
    height: 100%;
    .swiper-slide {
      align-items: center;
      background-size: cover, cover !important;
      display: flex;
      font-size: 18px;
      justify-content: center;
      height: 50vh;
      width: 100%;
      position: relative;
      text-align: center;
      @media only screen and (min-width: 425px) {
        height: 39vh;
      }

      // swiper-slide__image
      &__image {
        height: auto;
        width: 100%;
      }

      // swiper-slide__title
      &__title {
        color: $primary-font-color;
        margin: 5px 0;
      }

      // swiper-slide__item-duration, // swiper-slide__category
      &__item-duration, &__category {
        color: $secondary-font-color;
        margin: 0;
      }


      a:active, a:hover, a:visited {
        color: initial;
        text-decoration: none;
      }
    }
  }

  .home-swiper-pagination {
    z-index: 1;
  }
}



  .home-swiper-pagination {
    z-index: 1;
  }

  .header-info{
    width: 600px;
    height: 400px;
    position: absolute;
    color: white;

    @media screen and (min-width: 425px) and (max-width: 749px) {
      height: 14rem;
      margin-left: 6rem;
    }
    @media screen and (max-width: 425px) {
      display: none;
    }
    .header-title{
      font-size: 3.5rem;
      line-height: 4rem;
      text-align: start;
    }
    .star-rating-and__info{
      display: flex;
      align-items: center;
      margin-bottom: 17px;
    }
    .star{
      color: #2196f3;
      border: none;
      outline: none;
      background: transparent;
    }
    .synopsis {
      text-align: start;
      line-height: 1.6;
      font-family: $primary-font;
      color: #ffffff;
    }
    .watch--trailer{
      background-color: #202124;
      height: 55px;
      width: 173px;
      display: flex;
      align-items: center;
      margin-top: 50px;
      color: #fff;
      font-family: $textFont;
      font-size: .9rem;
      letter-spacing: 1.2px;
      justify-content: center;
      &:hover {
        background: $red;
        cursor: pointer;
        transition: 0.3s ease-in-out;
      }
      .watch--trailer__icon{
        padding-right: 10px;
        svg{
          display: flex;
        }
      }
    }
    .info{
      font-family: $headerFont;
      padding-left: 20px;
      color: #999999;
      @media screen and (min-width: 0px) and (max-width: 425px) {
        display: none;
    }
    }
  }


/*********************MEDIA QUERIES*******************************/

  @media screen and (min-width: 0px) and (max-width: 750px) {
    .home-swiper-container{
      height: 300px;
    }
    .opacity-wrapper{
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg,#000 0,rgba(0,0,0,.1) 50%,rgba(0,0,0,.1));
      opacity: .8;
      .watch--trailer{
        display: none;
      }
    }
    .synopsis{
      display: none;
    }
  }

  .opacity-wrapper{
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    opacity: .8;
  }



  @media screen and (min-width: 750px) and (max-width: 900px) {
    .opacity-wrapper{
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
      opacity: .9;
      .header-info{
        top: 13rem;
      }
      .synopsis{
        display: none;
      }
      .watch--trailer{
        display: none;
      }
    }
  }


  @media only screen and (min-width: 900px) {
    .home-swiper-container {
      .header-info{
        left: -10rem;
      }
      .swiper-wrapper{
        display: flex;
      .side-container{
        width: 30%;
        background: #000;
      }
      .swiper-slide {
        height: 60vh;
        width: 70%;
      }
     }
    }
  }

  
  
  @media only screen and (min-width: 1250px) {
    .home-swiper-container {
        .swiper-wrapper{
          display: flex;
          .side-container{
            width: 35%;
            background: #000;
          }
          .header-info{
            left: -18rem;
          }
          .swiper-slide {
            height: 60vh;
            width: 65%;
          }
        }
    }
  }  
